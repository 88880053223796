import './App.css';
import Carts from './carts/Carts';

function App() {
  

  return (
    <div className="App">
      <main className="App-main">
        <h2>Demo App by SEKKOUME</h2>
        <Carts></Carts>
      </main>
    </div>
  );
}

export default App;
